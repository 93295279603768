/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import { React } from "react";
// Material Dashboard 2 React layouts
import Lankomumas from "layouts/lankomumas";
import Suvestine from "layouts/lankomumo-suvestine";
import SuvestineVaiko from "layouts/vaiko-suvestine";
import Nariai from "layouts/nariai";
import NariaiVisi from "layouts/nariaiVisi";
import NariaiNauji from "layouts/nariaiNauji";
import VaikaiNauji from "layouts/vaikaiNauji";
import Pabandymai from "layouts/pabandymai";
import VaikuMokejimai from "layouts/vaikuMokejimai";
import Individualus from "layouts/individualus";
import NaujiNariai from "layouts/naujasNarys";
import NaujasMokejimas from "layouts/naujasMokejimas";
import NaujasNarysIndividualios from "layouts/naujasNarysIndividualios";
import NarioZona from "layouts/narioZona";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Vaikų lankomumas",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/dashboard",
    component: <Lankomumas />,
    level: 10,
  },
  {
    type: "collapse",
    name: "Grupės suvestinė",
    key: "suvestine",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/suvestine",
    component: <Suvestine />,
    level: 10,
  },
  {
    type: "collapse",
    name: "Pabandymai",
    key: "pabandymai-vaikai",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/pabandymai-vaikai",
    component: <Pabandymai />,
    level: 10,
  },
  {
    type: "collapse",
    name: "Sportuojančios grupės",
    key: "mano-nariai",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/mano-nariai",
    component: <Nariai />,
    level: 10,
  },
  {
    type: "collapse",
    name: "Vaiko narystė",
    key: "lankomumas",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/vaiko_lankomumas",
    component: <SuvestineVaiko />,
    level: 2,
  },
  {
    type: "collapse",
    name: "Mano narystė",
    key: "nario-zona-tetis",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/nario-zona-tetis",
    component: <NarioZona />,
    level: 2,
  },
  {
    type: "collapse",
    name: "Sportuojančios grupės",
    key: "mano-nariai",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/mano-nariai",
    component: <Nariai />,
    level: 0,
  },
  {
    type: "collapse",
    name: "Individualūs klientai",
    key: "individualus-klientai",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/individualus-klientai",
    component: <Individualus />,
    level: 0,
  },
  {
    type: "collapse",
    name: "Naujas kl.: Grupinės",
    key: "naujas-narys-grupines",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/naujas-narys",
    component: <NaujiNariai />,
    level: 0,
  },
  {
    type: "collapse",
    name: "Naujas kl.: Individualios",
    key: "naujas-narys-individualios",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/naujas-narys-individualios",
    component: <NaujasNarysIndividualios />,
    level: 0,
  },
  {
    type: "collapse",
    name: "Pabandymai",
    key: "pabandymai2",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/pabandymai2",
    component: <Pabandymai />,
    level: 0,
  },
  {
    type: "collapse",
    name: "Mano narystė",
    key: "nario-zona",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/nario-zona",
    component: <NarioZona />,
    level: 1,
  },
  {
    type: "collapse",
    name: "Studijos nariai",
    key: "visi-nariai",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/visi-nariai",
    component: <NariaiVisi />,
    level: 9,
  },
  {
    type: "collapse",
    name: "Treniruočių grupės",
    key: "mano-nariai-admin",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/mano-nariai-admin",
    component: <Nariai />,
    level: 9,
  },
  {
    type: "collapse",
    name: "Nauji nariai",
    key: "nauji-nariai",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/nauji-nariai",
    component: <NariaiNauji />,
    level: 9,
  },
  {
    type: "collapse",
    name: "Nauji vaikai",
    key: "nauji-vaikai",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/nauji-vaikai",
    component: <VaikaiNauji />,
    level: 9,
  },
  {
    type: "collapse",
    name: "Pabandymai",
    key: "pabandymai",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/pabandymai",
    component: <Pabandymai />,
    level: 9,
  },
  {
    type: "collapse",
    name: "Naujas kl.: Grupinės",
    key: "naujas-narys-grupines-admin",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/naujas-narys-admin",
    component: <NaujiNariai />,
    level: 9,
  },
  {
    type: "collapse",
    name: "Klientas atliko mokėjimą",
    key: "naujas-mokejimas-admin",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/naujas-mokejimas-admin",
    component: <NaujasMokejimas />,
    level: 9,
  },
  {
    type: "collapse",
    name: "Vaikų mokėjimai",
    key: "vaiku-mokejimai",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/vaiku-mokejimai",
    component: <VaikuMokejimai />,
    level: 9,
  },
  {
    type: "collapse",
    name: "Vaikų lankomumas",
    key: "suvestine-admin",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/suvestine-admin",
    component: <Suvestine />,
    level: 9,
  },
];

export default routes;
