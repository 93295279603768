/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TextField from "@mui/material/TextField";
import MDTypography from "components/MDTypography";

import cookie from "services/cookie.js";

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { suma: "0", nariai: [] };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    const token = cookie.getCookie("token");

    fetch("api/naujas-mokejimas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then(() => {
        alert("Naujas mokėjimas įtrauktas");
        location.reload();
      })
      .catch((err) => console.info(err));

    event.preventDefault();
  }

  componentDidMount() {
    const token = cookie.getCookie("token");

    fetch(`api/nariai/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) =>
        this.setState({ nariai: data.results, narys: data.results[0].id })
      );
  }

  render() {
    const { nariai } = this.state;

    function compare(a, b) {
      if (a.asmuo < b.asmuo) {
        return -1;
      }
      if (a.asmuo > b.asmuo) {
        return 1;
      }
      return 0;
    }

    return (
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        <MDBox>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Card>
                  <MDBox p={3}>
                    <form onSubmit={this.handleSubmit}>
                      <Grid item xs={12} lg={12} mb={2}>
                        <MDTypography
                          ariant="button"
                          fontWeight="regular"
                          variant="h5"
                        >
                          Sumokėjo grinaisiais?
                          <br />
                          Įvesk mokėjimą:
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} lg={12} mb={2}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Suma"
                          name="suma"
                          value={this.state.suma}
                          onChange={this.handleChange}
                          sx={{
                            height: "44px",
                            width: "100%",
                          }}
                        />
                      </Grid>
                      {nariai[0] ? (
                        <Grid item xs={12} lg={12} mb={2}>
                          <Select
                            id="outlined-select-currency"
                            label="Grupė"
                            name="narys"
                            sx={{
                              height: "44px",
                              width: "100%",
                            }}
                            value={this.state.narys}
                            onChange={this.handleChange}
                          >
                            {nariai?.sort(compare).map(function (item, i) {
                              return (
                                <MenuItem key={i} value={item.id}>
                                  {item.asmuo}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      ) : null}
                      <MDButton
                        color="success"
                        variant="gradient"
                        type="submit"
                      >
                        Registruoti
                      </MDButton>
                    </form>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default NameForm;
