/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TextField from "@mui/material/TextField";
import MDTypography from "components/MDTypography";

import cookie from "services/cookie.js";

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { vardas: "", pastas: "", numeris: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    const token = cookie.getCookie("token");

    fetch("api/naujas-narys-individualus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then(() => location.reload())
      .then(() => alert("Naujas narys įtrauktas"))
      .catch((err) => console.info(err));

    event.preventDefault();
  }

  render() {
    return (
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        <MDBox>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Card>
                  <MDBox p={3}>
                    <form onSubmit={this.handleSubmit}>
                      <Grid item xs={12} lg={12} mb={2}>
                        <MDTypography
                          ariant="button"
                          fontWeight="regular"
                          variant="h5"
                        >
                          Naujas klientas:
                          <br />
                          Individualios treniruotės
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} lg={12} mb={2}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Vardas ir Pavardė"
                          name="vardas"
                          onChange={this.handleChange}
                          sx={{
                            height: "44px",
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={12} mb={2}>
                        <TextField
                          required
                          id="outlined-required"
                          label="El. paštas"
                          name="pastas"
                          onChange={this.handleChange}
                          sx={{
                            height: "44px",
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={12} mb={2}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Tel. numeris"
                          name="numeris"
                          onChange={this.handleChange}
                          sx={{
                            height: "44px",
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <MDButton
                        color="success"
                        variant="gradient"
                        type="submit"
                      >
                        Registruoti
                      </MDButton>
                    </form>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default NameForm;
