import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Select from "@mui/material/Select";
import MDTypography from "components/MDTypography";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

import * as React from "react";
import dayjs from "dayjs";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MDButton from "components/MDButton";
import ProfileInfoCardVaikas from "examples/Cards/InfoCards/ProfileInfoCardVaikas";
import Payment from "layouts/vaiko-suvestine/components/Payment";

import cookie from "services/cookie.js";

export default function Suvestine() {
  const [value, setValue] = React.useState(dayjs().format("MM"));
  const [childsData, setChildsData] = React.useState(null);
  const [lankomumas, setLankomumas] = React.useState(null);
  const childRows = [];
  const token = cookie.getCookie("token");

  const returnChild = (name, email) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">Tėvai: {email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  function getAllDaysInMonth(year, month) {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push(dayjs(date).format("DD"));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  React.useEffect(() => {
    fetch(`api/vaikai/1`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setChildsData(data));
  }, []);

  React.useEffect(() => {
    fetch(`api/lankomumas/${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setLankomumas(data.results));
  }, [value]);

  const monthDays = getAllDaysInMonth(2023, Number(value) - 1);

  for (let i = 0; i < childsData?.results?.length; i += 1) {
    const childData = {
      vaikas: returnChild(
        childsData.results[i].vaikas,
        childsData.results[i].tevas
      ),
      kontaktai: childsData.results[i].pastas,
    };

    for (let j = 0; j < monthDays.length; j += 1) {
      const vaikoLankomumas = lankomumas
        ? lankomumas.filter(
            (person) =>
              person.vaikas_id === childsData.results[i].id &&
              dayjs(person.data).format("DD") === monthDays[j]
          )
        : [];

      childData[monthDays[j]] = vaikoLankomumas[0] ? (
        <MDButton
          variant="outlined"
          color="success"
          iconOnly
          circular
          size="small"
        >
          <Icon sx={{ fontWeight: "bold" }}>done_all</Icon>
        </MDButton>
      ) : null;
    }

    childRows.push(childData);
  }

  const columns = [{ Header: "vaikas", accessor: "vaikas", align: "left" }];

  for (let i = 0; i < monthDays.length; i += 1) {
    columns.push({
      Header: monthDays[i],
      accessor: monthDays[i],
      align: "left",
    });
  }

  const rows = childRows;

  console.info("childsData", childsData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={0}>
          <Grid item sm={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mėnuo</InputLabel>
              <Select
                value={value}
                label="Mėnuo"
                onChange={(newValue) => {
                  setValue(newValue.target.value);
                }}
                sx={{ height: "44px", marginBottom: "16px" }}
              >
                {/*
                <MenuItem value="03">Kovas</MenuItem>
                <MenuItem value="04">Balandis</MenuItem>
                <MenuItem value="05">Gegužė</MenuItem>
                <MenuItem value="06">Birželis</MenuItem>
                <MenuItem value="07">Liepa</MenuItem>
                <MenuItem value="08">Rugpjūtis</MenuItem>
                 */}

                <MenuItem value="09">Rugsėjis</MenuItem>
                <MenuItem value="10">Spalis</MenuItem>
                <MenuItem value="11">Lapkritis</MenuItem>
                <MenuItem value="12">Gruodis</MenuItem>
                <MenuItem value="01">Sausis</MenuItem>
                <MenuItem value="02">Vasaris</MenuItem>
                <MenuItem value="03">Kovas</MenuItem>
                <MenuItem value="04">Balandis</MenuItem>
                <MenuItem value="05">Gegužė</MenuItem>
                <MenuItem value="06">Birželis</MenuItem>
                <MenuItem value="07">Liepa</MenuItem>
                <MenuItem value="08">Rugpjūtis</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={0} mb={0} py={3} px={2}>
                <MDTypography variant="h5" fontWeight="medium">
                  Sportuojančių vaikų informacija
                </MDTypography>
              </MDBox>
              <MDBox mx={0} mb={0} pb={3} px={2}>
                <MDTypography variant="h6" fontWeight="regular">
                  Pasirinkto mėnesio ({value}) lankomumas:
                </MDTypography>
              </MDBox>
              <MDBox pt={0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
              <MDBox mx={0} mb={0} py={3} px={2}>
                <MDTypography variant="h6" fontWeight="regular">
                  Informacija apie apmokėjimą:
                </MDTypography>
              </MDBox>
              <MDBox mx={0} mb={0} pb={3} px={2}>
                {childsData?.results?.map(function (item, i) {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      xl={12}
                      sx={{ display: "flex" }}
                      key={i}
                    >
                      <ProfileInfoCardVaikas
                        info={{
                          "Vaiko vardas": item.vaikas,
                          "Mokestinis laikotarpis": `Nuo einamojo mėnesio 1 dienos, iki sekančio mėnesio 1 dienos.`,
                          "Reikia sumokėti": `${item.liko_sumoketi} EUR.`,
                          Apmokėti: <Payment user={item} />,
                        }}
                        shadow={false}
                      />
                    </Grid>
                  );
                })}
              </MDBox>
              <MDBox mx={0} mb={0} mt={-2} pb={3} px={2}>
                <MDTypography variant="h6" fontWeight="bold">
                  Kaip skaičiuojamas apmokėjimas?
                </MDTypography>
                <MDTypography variant="h6" fontWeight="regular">
                  Už treniruotes mokama mėnesiui į priekį. Apmokėjimas už
                  treniruotes skaičiuojamas remiantis buvusio mėnesio lankomumu
                  - jūsų vaiko mokėjimai skaičiuojasi imant laikotarpį nuo
                  praėjusio mėnesio 1 dienos iki šio mėnesio 1 dienos. Jei per
                  šį laikotarpį apsilankėte:
                  <br />- <b>Nuo 0 iki 4 treniruočių</b>: Už sekantį mėnesį bus
                  paprašyta susimokėti puse mėnesio sumos, bet lankytis galite
                  visose treniruotėse. Nelankėte praėjusį mėnesį daugiau nei
                  pusės per mėnesį vykstančių treniruočių, dėl to sekančio
                  mėnesio mokėjimas mažinamas 50 proc.
                  <br />- <b>5 ir daugiau treniruočių</b>: Už sekantį mėnesį bus
                  paprašyta sumokėti pilną mėnesio sumą.
                  <br />
                  Už treniruotes prašome susimokėti per 16 mėnesio dienos.
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
