import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

import * as React from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Button from "@mui/material/Button";

import cookie from "services/cookie.js";

export default function Suvestine() {
  const [nariai, setNariai] = React.useState([]);
  const token = cookie.getCookie("token");
  const currentUser = cookie.getUser("token");

  function sendNotificationSlepti(data) {
    const id = data.target.id;

    console.info(id);

    if (!id) {
      return;
    }

    fetch("api/vaiku-mokejimai-siusti", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));
  }

  React.useEffect(() => {
    fetch(`api/vaiku-mokejimai/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setNariai(data.results));
  }, []);

  const columns = [
    { Header: "Vaikas", accessor: "vaikas", align: "left" },
    { Header: "Tėvai", accessor: "tevas", align: "left" },
    { Header: "Data", accessor: "data", align: "left" },
    { Header: "El. paštas", accessor: "pastas", align: "left" },
    { Header: "Suma", accessor: "nauja", align: "left" },
  ];

  if (currentUser.level == 9) {
    columns.push({
      Header: "Siųsti",
      accessor: "slepti",
      align: "left",
      width: "20px",
    });
  }

  let rows = nariai ? JSON.parse(JSON.stringify(nariai)) : [];

  for (let i = 0; i < rows.length; i++) {
    if (currentUser.level == 9) {
      rows[i].slepti = (
        <Button
          variant="text"
          id={rows[i].id}
          onClick={(e) => sendNotificationSlepti(e)}
        >
          Siųsti
        </Button>
      );
    }
  }

  function compare(a, b) {
    if (a.data < b.data) {
      return -1;
    }
    if (a.data > b.data) {
      return 1;
    }
    return 0;
  }

  rows.sort(compare);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDTypography
                mt={3}
                ml={3}
                ariant="button"
                fontWeight="regular"
                variant="h5"
              >
                Užsiregistravę pabandyti:
              </MDTypography>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
