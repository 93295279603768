import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

import * as React from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import cookie from "services/cookie.js";

export default function Suvestine() {
  const [nariai, setNariai] = React.useState([]);
  const token = cookie.getCookie("token");

  React.useEffect(() => {
    fetch(`api/individualus/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setNariai(data.results));
  }, []);

  const columns = [
    { Header: "Asmuo", accessor: "asmuo", align: "left" },
    { Header: "El. paštas", accessor: "pastas", align: "left" },
    { Header: "Tel. numeris", accessor: "numeris", align: "left" },
    { Header: "Įvestas", accessor: "data", align: "left" },
    { Header: "Sutartis", accessor: "sutartis", align: "left" },
  ];

  let rows = nariai ? JSON.parse(JSON.stringify(nariai)) : [];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDTypography
                ariant="button"
                fontWeight="regular"
                variant="h5"
                mt={3}
                ml={3}
              >
                Individualūs klientai:
              </MDTypography>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
